function About() {
    return ( 
        <>
        <div class="container-fluid about overflow-hidden py-5">
                <div class="container py-5">
                    <div class="row g-5">
                        <h1 class="text-primary text-uppercase text-center display-4">About Us</h1>
                        <div class="col-xl-6 wow fadeInLeft mt-5" data-wow-delay="0.2s">
                            <div class="about-img rounded h-100">
                                <img src="About.jpg" class="img-fluid rounded h-100 w-100" style={{ objectFit: " cover" }} alt="" />
                                <div class="about-exp"><span>4 Years Experiance</span></div>
                            </div>
                        </div>
                        <div class="col-xl-6 wow fadeInRight" data-wow-delay="0.2s">
                            <div class="about-item">

                                <h1 class="display-3 mb-3 mt-5">We Deliver The Quality Water.</h1>
                                <p class="mb-4">Incepted in the year 2020, "Shivarn Technologies” is distinguished Trader - Retailer offering an enormous consignment of Grundfos Pump etc.
                                </p>
                                <div class="bg-light rounded p-4 mb-4">
                                    <div class="row">
                                        <div class="col-12">
                                            <div class="d-flex">
                                                <div class="pe-4">
                                                    <div class="rounded-circle bg-primary d-flex align-items-center justify-content-center" style={{ width: "80px", height: "80px" }}><i class="fas fa-tint text-white fa-2x"></i></div>
                                                </div>
                                                <div class="">
                                                    <a href="#" class="h4 d-inline-block mb-3">Satisfied Customer</a>
                                                    <p class="mb-0">Clearly state your dedication to providing high-quality products or services that meet customer needs.</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="bg-light rounded p-4 mb-4">
                                    <div class="row">
                                        <div class="col-12">
                                            <div class="d-flex">
                                                <div class="pe-4">
                                                    <div class="rounded-circle bg-primary d-flex align-items-center justify-content-center" style={{ width: "80px", height: " 80px" }}><i class="fas fa-faucet text-white fa-2x"></i></div>
                                                </div>
                                                <div class="">
                                                    <a href="#" class="h4 d-inline-block mb-3">Standard Product</a>
                                                    <p class="mb-0">Provide an overview of the types of products you offer, such as filtration systems, bottled water, water-saving devices, etc., emphasizing their quality.</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <a href="https://www.indiamart.com/shivarn-technologies/profile.html" class="btn btn-secondary rounded-pill py-3 px-5">Read More</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
     );
}

export default About;