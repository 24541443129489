import About_section from "./About_section";
import Counter_section from "./Counter_section";
import Product_section from "./Product_section";
import Service_section from "./Service_section";

function Home() {
    return ( 
        <>
           
           <About_section/>
           <Counter_section/>
           <Service_section/>
           <Product_section/>
           
        </>
     );
}

export default Home;