import React from 'react';
import { useNavigate,useLocation } from 'react-router-dom';

function HeaderSection() {
    const navigate = useNavigate();
    const location = useLocation();
    return (
        <>
            <div className="container-fluid position-relative p-0">
                <nav className="navbar navbar-expand-lg navbar-light px-4 px-lg-5 py-3 py-lg-0">
                    <a href="#" className="navbar-brand p-0 d-flex align-items-center">
                        {/* Logo Image */}
                        <img src="LOGO.png" alt="Logo" className="me-2" style={{ height: '40px' }} />
                        <h1 className="text-primary">
                            Shivarn
                        </h1>
                    </a>
                    <button
                        className="navbar-toggler"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#navbarCollapse"
                    >
                        <span className="fa fa-bars"></span>
                    </button>
                    <div className="collapse navbar-collapse" id="navbarCollapse">
                        <div className="navbar-nav ms-auto py-0">
                        <a
                href="#"
                className={`nav-item nav-link ${location.pathname === '/' ? 'active' : ''}`}
                onClick={(e) => {
                    e.preventDefault();
                    navigate('/');
                }}
            >
                Home
            </a>
            <a
                href="#"
                className={`nav-item nav-link ${location.pathname === '/about' ? 'active' : ''}`}
                onClick={(e) => {
                    e.preventDefault();
                    navigate('/about');
                }}
            >
                About
            </a>
            <a
                href="#"
                className={`nav-item nav-link ${location.pathname === '/service' ? 'active' : ''}`}
                onClick={(e) => {
                    e.preventDefault();
                    navigate('/service');
                }}
            >
                Service
            </a>
            <a
                href="#"
                className={`nav-item nav-link ${location.pathname === '/product' ? 'active' : ''}`}
                onClick={(e) => {
                    e.preventDefault();
                    navigate('/product');
                }}
            >
                Products
            </a>
            {/* <a
                href="#"
                className={`nav-item nav-link ${location.pathname === '/Condition' ? 'active' : ''}`}
                onClick={(e) => {
                    e.preventDefault();
                    navigate('/Condition');
                }}
            >
                Condition
            </a> */}

            <div class="nav-item dropdown">
                            <a href="#" class="nav-link dropdown-toggle" data-bs-toggle="dropdown">Pages</a>
                            <div class="dropdown-menu m-0">
                            <a
                href="#"
                className={`nav-item nav-link${location.pathname === '/Condition' ? 'active' : ''}`}
                onClick={(e) => {
                    e.preventDefault();
                    navigate('/Condition');
                }}
            >
                Condition
            </a>
            <a
                href="#"
                className={`nav-item nav-link ${location.pathname === '/Privacy' ? 'active' : ''}`}
                onClick={(e) => {
                    e.preventDefault();
                    navigate('/Privacy');
                }}
            >
                Privacy
            </a>
            <a
                href="#"
                className={`nav-item nav-link ${location.pathname === '/Refund' ? 'active' : ''}`}
                onClick={(e) => {
                    e.preventDefault();
                    navigate('/Refund');
                }}
            >
                Refund
            </a>
            <a
                href="#"
                className={`nav-item nav-link ${location.pathname === '/Return' ? 'active' : ''}`}
                onClick={(e) => {
                    e.preventDefault();
                    navigate('/Return');
                }}
            >
                Return
            </a>
            <a
                href="#"
                className={`nav-item nav-link ${location.pathname === '/Shipping' ? 'active' : ''}`}
                onClick={(e) => {
                    e.preventDefault();
                    navigate('/Shipping');
                }}
            >
                Shipping
            </a>
                            </div>
                        </div>
            <a
                href="#"
                className={`nav-item nav-link ${location.pathname === '/contact' ? 'active' : ''}`}
                onClick={(e) => {
                    e.preventDefault();
                    navigate('/contact');
                }}
            >
                Contact
            </a>
                            {/* <a href="index.html" className="nav-item nav-link active">Home</a>
                            <a  className="nav-item nav-link"
                            onClick={(e) => {
                                e.preventDefault(); // Prevent the default navigation
                                navigate('/about'); // Navigate to the About route
                            }}>
                                About</a>
                            <a  onClick={(e) => {
                                e.preventDefault(); // Prevent the default navigation
                                navigate('/service'); // Navigate to the About route
                            }} className="nav-item nav-link">Service</a> */}
                            {/* <a href="blog.html" className="nav-item nav-link">Blog</a> */}
                            {/* <div className="nav-item dropdown">
                                <a href="#" className="nav-link dropdown-toggle" data-bs-toggle="dropdown">Pages</a>
                                <div className="dropdown-menu m-0">
                                    <a href="feature.html" className="dropdown-item">Our Feature</a>
                                    <a href="product.html" className="dropdown-item">Our Product</a>
                                    <a href="team.html" className="dropdown-item">Our Team</a>
                                    <a href="testimonial.html" className="dropdown-item">Testimonial</a>
                                    <a href="404.html" className="dropdown-item">404 Page</a>
                                </div>
                            </div> */}
                            {/* <a onClick={(e) => {
                                e.preventDefault(); // Prevent the default navigation
                                navigate('/contact'); // Navigate to the About route
                            }} className="nav-item nav-link">Contact</a> */}
                        </div>
                        <div className="d-none d-xl-flex me-3">
                            <div className="d-flex flex-column pe-3 border-end border-primary">
                                <span className="text-body">Get Free Delivery</span>
                                <a href="tel:+4733378901">
                                    <span className="text-primary">Call:+91-9978070593</span>
                                </a>
                            </div>
                        </div>
                        <button
                            className="btn btn-primary btn-md-square d-flex flex-shrink-0 mb-3 mb-lg-0 rounded-circle me-3"
                            data-bs-toggle="modal"
                            data-bs-target="#searchModal"
                        >
                            <i className="fas fa-search"></i>
                        </button>
                        {/* <a href="#" className="btn btn-primary rounded-pill d-inline-flex flex-shrink-0 py-2 px-4">Order Now</a> */}
                    </div>
                </nav>

                {/* Carousel Start */}
                <div className="carousel-header">
                    <div id="carouselId" className="carousel slide" data-bs-ride="carousel">
                        <ol className="carousel-indicators">
                            <li data-bs-target="#carouselId" data-bs-slide-to="0" className="active"></li>
                            <li data-bs-target="#carouselId" data-bs-slide-to="1"></li>
                            <li data-bs-target="#carouselId" data-bs-slide-to="2"></li>
                        </ol>
                        <div className="carousel-inner" role="listbox">
                            <div className="carousel-item active">
                                <img src="img/carousel-1.jpg" className="img-fluid w-100" alt="Image" />
                                <div className="carousel-caption-1">
                                    <div className="carousel-caption-1-content" style={{ maxWidth: '900px' }}>
                                        <h4 className="text-white text-uppercase fw-bold mb-4 fadeInLeft animated" data-animation="fadeInLeft" data-delay="1s" style={{ animationDelay: '1s', letterSpacing: '3px' }}>
                                            Importance life
                                        </h4>
                                        <h1 className="display-2 text-capitalize text-white mb-4 fadeInLeft animated" data-animation="fadeInLeft" data-delay="1.3s" style={{ animationDelay: '1.3s' }}>
                                            Always Want Safe Water For Healthy Life
                                        </h1>
                                        <p className="mb-5 fs-5 text-white fadeInLeft animated" data-animation="fadeInLeft" data-delay="1.5s" style={{ animationDelay: '1.5s' }}>
                                            "Discover the essential resource for life. Our commitment to clean, safe water ensures you and your family enjoy the best hydration every day."
                                        </p>
                                        <div className="carousel-caption-1-content-btn fadeInLeft animated" data-animation="fadeInLeft" data-delay="1.7s" style={{ animationDelay: '1.7s' }}>
                                            <a className="btn btn-primary rounded-pill flex-shrink-0 py-3 px-5 me-2" href="#">Visit Now</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="carousel-item">
                                <img src="img/carousel-2.jpg" className="img-fluid w-100" alt="Image" />
                                <div className="carousel-caption-2">
                                    <div className="carousel-caption-2-content" style={{ maxWidth: '900px' }}>
                                        <h4 className="text-white text-uppercase fw-bold mb-4 fadeInRight animated" data-animation="fadeInRight" data-delay="1s" style={{ animationDelay: '1s', letterSpacing: '3px' }}>
                                            Importance life
                                        </h4>
                                        <h1 className="display-2 text-capitalize text-white mb-4 fadeInRight animated" data-animation="fadeInRight" data-delay="1.3s" style={{ animationDelay: '1.3s' }}>
                                            Always Want Safe Water For Healthy Life
                                        </h1>
                                        <p className="mb-5 fs-5 text-white fadeInRight animated" data-animation="fadeInRight" data-delay="1.5s" style={{ animationDelay: '1.5s' }}>
                                            "Discover the essential resource for life. Our commitment to clean, safe water ensures you and your family enjoy the best hydration every day."
                                        </p>
                                        <div className="carousel-caption-2-content-btn fadeInRight animated" data-animation="fadeInRight" data-delay="1.7s" style={{ animationDelay: '1.7s' }}>
                                            <a className="btn btn-primary rounded-pill flex-shrink-0 py-3 px-5 me-2" href="#">Visit Now</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <button className="carousel-control-prev" type="button" data-bs-target="#carouselId" data-bs-slide="prev">
                            <span className="carousel-control-prev-icon btn btn-primary fadeInLeft animated" aria-hidden="true" data-animation="fadeInLeft" data-delay="1.1s" style={{ animationDelay: '1.3s' }}>
                                <i className="fa fa-angle-left fa-3x"></i>
                            </span>
                            <span className="visually-hidden">Previous</span>
                        </button>
                        <button className="carousel-control-next" type="button" data-bs-target="#carouselId" data-bs-slide="next">
                            <span className="carousel-control-next-icon btn btn-primary fadeInRight animated" aria-hidden="true" data-animation="fadeInLeft" data-delay="1.1s" style={{ animationDelay: '1.3s' }}>
                                <i className="fa fa-angle-right fa-3x"></i>
                            </span>
                            <span className="visually-hidden">Next</span>
                        </button>
                    </div>
                </div>
                {/* Carousel End */}
            </div>
        </>
    );
}

export default HeaderSection;
