import { useEffect } from "react";

function Counter_section() {
    useEffect(() => {
        const counters = document.querySelectorAll('.js-counter');
        counters.forEach(counter => {
            const updateCount = () => {
                const target = +counter.getAttribute('data-to');
                const speed = +counter.getAttribute('data-speed');
                const count = +counter.innerText;
                const increment = target / (speed / 100);

                if (count < target) {
                    counter.innerText = Math.ceil(count + increment);
                    setTimeout(updateCount, 50);
                } else {
                    counter.innerText = target + '+'; // Add + sign here
                }
            };
            updateCount();
        });
    }, []);

    return (
        <>
            <div id="fh5co-counter-section" className="fh5co-counters">
                <div className="container">
                    <div className="row">
                        <div className="col-md-3 text-center">
                            <span className="fh5co-counter js-counter" data-from="0" data-to="500" data-speed="8000"></span>
                            <span className="fh5co-counter-label">Happy Clients</span>
                        </div>
                        <div className="col-md-3 text-center">
                            <span className="fh5co-counter js-counter" data-from="0" data-to="10" data-speed="8000"></span>
                            <span className="fh5co-counter-label">Transport</span>
                        </div>
                        <div className="col-md-3 text-center">
                            <span className="fh5co-counter js-counter" data-from="0" data-to="10" data-speed="8000"></span>
                            <span className="fh5co-counter-label">Employees</span>
                        </div>
                        <div className="col-md-3 text-center">
                            <span className="fh5co-counter js-counter" data-from="0" data-to="4" data-speed="8000"></span>
                            <span className="fh5co-counter-label">Years Experience  </span>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Counter_section;
