import React from 'react';

function Phonepay_section() {
  return (
    <>
      <style>
        {`
          /* Reset some default styles */

          /* Remove default button and anchor link styles */
          a {
            text-decoration: none; /* Remove underline from anchor link */
          }

          .phonepay-btn {
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: #0062cc; /* PhonePe-like blue color */
            color: white;
            font-size: 16px;
            font-weight: bold;
            padding: 12px 24px;
            border-radius: 25px;
            border: none;
            cursor: pointer;
            outline: none;
            transition: background-color 0.3s ease;
          }

          .phonepay-btn:hover {
            background-color: #0055b8; /* Darker blue on hover */
          }

          .phonepay-btn:focus {
            outline: none; /* Removes the default focus outline */
          }

          /* Styling for the icon (using an emoji here) */
          .phonepay-icon {
            margin-right: 8px; /* Space between icon and text */
            font-size: 20px; /* Icon size */
          }

          /* Responsive Design: Make the button smaller on mobile screens */
          @media (max-width: 600px) {
            .phonepay-btn {
              font-size: 14px;
              padding: 10px 20px;
            }

            .phonepay-icon {
              font-size: 18px; /* Slightly smaller icon on small screens */
            }
          }
        `}
      </style>

      {/* PhonePay-like button with an emoji icon */}
      <a href="#" className="btn back-to-top">
        <button className="phonepay-btn">
          <span className="phonepay-icon">📱</span> {/* Using a phone emoji as the icon */}
          PhonePay
        </button>
      </a>
    </>
  );
}

export default Phonepay_section;
