function Refund() {
    return ( 
        <>
        <div className="flex flex-col items-center justify-center w-full p-[50%]">
        <h1 className="text-2xl font-bold mb-4 text-center">Refund and cancellation Policy</h1>
        <div className="w-4/5 text-justify ">
        <div  style={{margin:"0px 83px"}}>

            <p>This refund and cancellation policy outlines how you can cancel or seek a refund for a product / service 
that you have purchased through the Platform. Under this policy:
</p>
<p>
            <span className="font-semibold">1.</span> Cancellations will only be considered if the request is made 7 days of placing the order. However, 
cancellation requests may not be entertained if the orders have been communicated to such sellers / 
merchant(s) listed on the Platform and they have initiated the process of shipping them, or the 
product is out for delivery. In such an event, you may choose to reject the product at the doorstep
          </p>
          <p>
            <span className="font-semibold">2.</span> SHIVARN TECHNOLOGIES does not accept cancellation requests for perishable items like 
flowers, eatables, etc. However, the refund / replacement can be made if the user establishes that 
the quality of the product delivered is not good.

          </p>
          <p>
            <span className="font-semibold">3.</span> In case of receipt of damaged or defective items, please report to our customer service team. The 
request would be entertained once the seller/ merchant listed on the Platform, has checked and 
determined the same at its own end. This should be reported within 7 days of receipt of products. 
In case you feel that the product received is not as shown on the site or as per your expectations, 
you must bring it to the notice of our customer service within 7 days of receiving the product. The 
customer service team after looking into your complaint will take an appropriate decision
          </p>
          <p>
            <span className="font-semibold">4.</span> In case of complaints regarding the products that come with a warranty from the manufacturers, 
            please refer the issue to them.
          </p>
          <p>
            <span className="font-semibold">5.</span> In case of any refunds approved by SHIVARN TECHNOLOGIES, it will take 7 days for the 
            refund to be processed to you.
          </p>
        </div>
        </div>
        </div>
        </>
     );
}

export default Refund;