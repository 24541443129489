import logo from './logo.svg';
import './App.css';
import Home from './Home';
import { BrowserRouter as Router,Routes,Route,Link } from 'react-router-dom';
import About from './About';
import Service from './Service';
import Contact from './Contact';
import HeaderSection from './Header_section';
import Contact_section from './Contact_section';
import Phonepay_section from './Phonepay_section';
import Product from './Product';
import Condition from './Condition';
import Privacy from './Privacy';
import Refund from './Refund';
import Return from './Return';
import Shipping from './Shipping';

function App() {
  return (
    <Router>
    <HeaderSection/>
    
    <Routes>
      <Route path='/' element={<Home/>}/>
      <Route path='/about' element={<About/>}/>
      <Route path='/service' element={<Service/>}/>
      <Route path='/product' element={<Product/>}/>
      <Route path='/condition' element={<Condition/>}/>
      <Route path='/privacy' element={<Privacy/>}/>
      <Route path='/refund' element={<Refund/>}/>
      <Route path='/return' element={<Return/>}/>
      <Route path='/shipping' element={<Shipping/>}/>
      <Route path='/contact/' element={<Contact/>}/>
    </Routes>
   <Contact_section/>
   <Phonepay_section/>
  </Router>
  );
}

export default App;
